export const SUBCATEGORIES = {
    "Operations": {
      description:  "I need help to create a course on computer networking...",
      subcategories: {
        "Learning Management Systems":  {
            description: "systems to manage course functions online",
        },
        "Videocasting Platforms": {
            description: "software to help you record and broadcast videos",
        },
        "Examination Platforms": {
            description: "platforms to help you host exams/quizzes online",
        },
        "Interaction Platforms": {
            description: "platforms to help break the ice and create interactions between your students and team members",
        },
        "Publishing/Document Writing": {
            description: "platforms to help you write, typeset, and disseminate documents", 
        },
        "Games": {
            description: "games that help teach networking",
        },
        "Video Calling Platforms": {
            description: "platforms that can help you make and host online meetings",
        },
        "Event Hosting": {
            description: "platforms to help you host the online part of virtual or hybrid conferences and other events",
        }
      },
    },
    "Materials": {
      description: "I am looking for content to put into a class or learn on my own... ",
      subcategories: {
        "Videos and Online Courses": {
            description: "courses and videos that can teach you more about networking",
        },
        "Syllabuses": {
            description: "outline of subjects to be covered in networking courses",
        },
        "Homeworks/Lab Assignments": {
            description: "already-created assignments you can do or assign to your students to learn more about networking",
        },
        "Books/Documentation": {
            description: "online or physical books about networking and their supporting websites/materials", 
        },
        "Audio/Podcasts": {
            description: "platforms that enable creation of websites and other infrastructure",
        },
      },
    },
    "Experiments": {
        description:"I am looking for software to help me build real networks or simulate/test my networked designs...",
        subcategories: {
          "SDN Controllers": {
            description: "server software for the “brains” of a software-defined network",
          },
          "Testbeds": {
            description: "resources to help you build physical or virtual testbeds",
          },
          "Network Tools": {
            description: "tools to generate packets, analyze probes, and other sorts of actions with networks",
          },
          "Data Repositories": {
            description: "sites that have big bunches of real network traces that you can use for testing and such",
          },
          "Visualizations/Analyzers": {
            description: "resources that can help you visualize aspects of computer networks", 
          },
          "Network Emulators": {
            description: "platforms that help you set up testbeds of emulated networks",
          },
          "Simulators": {
            description: "platforms to help you simulate networks"
          },
          "Network Device Implementations": {
            description: "resources to help you implement or run your own network routers/switches/etc.",
          }
        },
      },

      "Community": {
        description: "I am looking to meet other people who are also interested in ...",
        subcategories: {
          "Professional Societies": {
            description: "groups you can join to meet other people interested in computer networking",
          },
          "Mailing Lists and News Feeds": {
            description: "places you can get up to date news and updates on computer networking",
          },
          "Social Media and Chat": {
            description: "Social media platform you can finds computer networking groups and chanel",
          },
        },
      },
  };