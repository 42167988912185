import { WithFooter } from '../../components/Footer';
import { Navbar } from '../../components/Navbar';

import { Text, Center } from '@chakra-ui/react';

export const WishlistPage = () => {
  return (
    <WithFooter>
      <Navbar />
      <>
        {/* <Center py={10}> */}
        <Text fontSize="lg" textAlign="center">
          We welcome feedback and suggestions for content to be included on this
          site.
          Please email us at caesar@illinois.edu.
          
          Submit below any ideas for resources you would like the community to
          implement in the future, even if they are not currently available.
        </Text>
        {/* </Center> */}
        <Center py={2}>
          <iframe
            title="Resources Form"
            src="https://forms.gle/chUAP64y2BbRdVcy9"
            width="700"
            height="700"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </Center>
      </>
    </WithFooter>
  );
};
