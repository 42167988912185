import { Box, SimpleGrid, useColorModeValue as mode } from '@chakra-ui/react';
import { WithFooter } from '../../components/Footer';
import { Navbar } from '../../components/Navbar';
import { CategoryCard2 } from '../../components/CategoryCard2';
import { Heading } from '@chakra-ui/react';
import { SUBCATEGORIES } from '../../subcategories';
import { useParams } from 'react-router-dom';

import { chakra} from '@chakra-ui/react';

export const SubcategoryPage = () => {
    const { category: categoryName } = useParams();  
    const subcategory = SUBCATEGORIES[categoryName].subcategories
  
    const categoriesList = Object.keys(subcategory).map(
      function(key, index) 
      {const subcategoryName = key; // Category name
      const description = subcategory[key].description; // Category description from CATEGORIES object
      return <CategoryCard2 key={index} subcategory={subcategoryName} description={description} category={categoryName} />;});
       
    return (
        <WithFooter>
            <Navbar />
    
            <Heading pt={10} pb={2} textAlign="center" mx="auto" fontSize="3xl"> {categoryName} </Heading>
            <center pt={4}>
                <chakra.h2> 
                {SUBCATEGORIES[categoryName].description}
                </chakra.h2>
            </center>
            <Box
                as="section"
                bg={mode('white', 'grey.800')}
                // px={{ base: '6', md: '8' }}
                py={{ base: '10', sm: '24' }}
                maxW={{ base: 'xl', md: '7xl' }}
                mx="auto"
                mt={3}
            >
                <SimpleGrid columns={{ base: 2, lg: 2 }} spacing="16" mb="10">
                {categoriesList}
                </SimpleGrid>
            </Box>
        </WithFooter>
        );
}